import React from 'react';
import { Link } from 'gatsby';
import { FaChevronLeft } from 'react-icons/fa';
import '../components/style.scss';

const spotChatBot = () => (
  <section className="section">
    <div className="container">
      <div className="columns">
        <Link to="/#projects">
          <button
            type="button"
            className="button is-outlined is-primary is-medium"
          >
            <FaChevronLeft />
          </button>
        </Link>
        <div className="column is-half is-size-5">
          <h1 className="title is-size-1 is-centered">
            Spot - A Music ChatBot
          </h1>
          Have you ever wanted to listen to music but you can't pinpoint exactly
          what music you're in the mood for? Say hello to Spot, a music chatbot
          who through a few quick prompts, can analyze a user's sentiment and
          provide a song recommendation for you based on how you're feeling on
          any given day. The root of the project was constructed utilizing
          webhooks on Facebook's messenger platform, the Watson tone analysis
          API, and Spotify's music recommendation API.
          <br />
          <br />
          <a
            className="text is-size-5"
            href="https://github.com/sjr765/Spot_ChatBot"
          >
            https://github.com/sjr765/Spot_ChatBot
          </a>
          <br />
          <br />
          <iframe
            title="spot"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/HFPiIooImD0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  </section>
);

export default spotChatBot;
